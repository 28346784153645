import { useEffect, useRef, useState } from 'react';
import type { Reducer } from 'redux';

import logger from '../../observability/logger';
import { IS_LOCAL } from '../../platform/constants';
import { useStoreManager } from './useStoreManager';

export function useLazyReducer(
  reducerKey: string,
  promisedReducer: () => Promise<Reducer<any, any>>,
  options: {
    allowReInjection?: boolean;
    clean?: boolean;
    condition: boolean;
    disableReturnInjectedState?: false;
  },
): { reducerInjected: boolean };

export function useLazyReducer(
  reducerKey: string,
  promisedReducer: () => Promise<Reducer<any, any>>,
  options: {
    allowReInjection?: boolean;
    clean?: boolean;
    condition: boolean;
    disableReturnInjectedState?: true;
  },
): void;

export function useLazyReducer(
  reducerKey: string,
  promisedReducer: () => Promise<Reducer<any, any>>,
  options: {
    allowReInjection?: boolean;
    clean?: boolean;
    condition: boolean;
    disableReturnInjectedState?: boolean;
  },
): { reducerInjected: boolean } | void {
  const {
    allowReInjection = false,
    clean = false,
    condition,
    disableReturnInjectedState = false,
  } = options;
  const { storeManager } = useStoreManager();
  const [reducerInjected, setReducerInjected] = useState(false);
  const ref = useRef<Reducer<any, any>>();

  useEffect(() => {
    if (condition) {
      promisedReducer().then((reducer) => {
        ref.current = reducer;
        const reducerStatus = storeManager?.getReducerStatus(reducerKey);

        if (IS_LOCAL) {
          const {
            isApplicationStateWarningsEnabled,
            // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
          } = require('@/devTools/DevTools/components/Performance/applicationStateWarnings');

          if (
            isApplicationStateWarningsEnabled() &&
            condition &&
            reducerStatus === 'registered' &&
            !allowReInjection
          ) {
            logger.error(
              Error(
                `Reducer with key "${reducerKey}" has already been registered. This useLazyReducer() call could be avoided.`,
              ),
            );
          }
        }

        if (
          reducerStatus === 'not-registered' ||
          reducerStatus === 'replaceable' ||
          allowReInjection
        ) {
          storeManager?.injectReducer(reducerKey, reducer);
        }

        if (!disableReturnInjectedState) {
          setReducerInjected(true);
        }
      });
    }
  }, [
    allowReInjection,
    condition,
    disableReturnInjectedState,
    promisedReducer,
    reducerKey,
    storeManager,
  ]);

  // We use a second useEffect because "condition" change will re-trigger the
  // effect: unmount + mount
  useEffect(
    () => () => {
      if (clean) {
        if (!ref.current) {
          return;
        }
        storeManager?.removeReducer(reducerKey);
      }
    },
    [clean, reducerKey, storeManager],
  );

  if (!disableReturnInjectedState) {
    return { reducerInjected };
  }
}
