import { useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';

import logger from '../../observability/logger';
import { IS_LOCAL } from '../../platform/constants';
import { SAGA_INJECTED } from '../saga/SagaRegistry';
import { useStoreManager } from './useStoreManager';

export function useLazySaga(
  sagaKey: string,
  promisedSaga: () => Promise<() => Generator<unknown, any, unknown>>,
  options: { allowReInjection?: boolean; condition: boolean },
): { sagaInjected: boolean } {
  const { allowReInjection = false, condition } = options;
  const [, updateState] = useState<Record<never, never>>();
  const { storeManager } = useStoreManager();
  const isInjected = storeManager?.isSagaInjected(sagaKey) || false;

  const subscriberId = useMemo(v4, []);

  const sagaInjectedHandler = () => {
    updateState({});
  };

  useEffect(() => {
    storeManager?.addSagaEventListener(
      { type: SAGA_INJECTED, sagaKey },
      sagaInjectedHandler,
    );
    return storeManager?.removeSagaEventListener(
      { type: SAGA_INJECTED, sagaKey },
      sagaInjectedHandler,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (condition) {
      promisedSaga().then((saga) => {
        const wasInjected = isInjected;
        const isInjectedNow = storeManager?.isSagaInjected(sagaKey);

        if (IS_LOCAL) {
          const {
            isApplicationStateWarningsEnabled,
            // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
          } = require('@/devTools/DevTools/components/Performance/applicationStateWarnings');

          if (
            isApplicationStateWarningsEnabled() &&
            condition &&
            isInjectedNow &&
            !allowReInjection
          ) {
            logger.error(
              Error(
                `Saga with key "${sagaKey}" has already been registered. This useLazySaga() call could be avoided.`,
              ),
            );
          }
        }

        if (!isInjectedNow || allowReInjection) {
          storeManager?.requireSaga(sagaKey, saga, subscriberId);
        }

        if (!wasInjected) {
          updateState({});
        }
      });
    }
  }, [
    allowReInjection,
    condition,
    promisedSaga,
    sagaKey,
    storeManager,
    subscriberId,
    isInjected,
  ]);

  // We use a second useEffect because "condition" change will re-trigger the
  // effect: unmount + mount
  useEffect(
    () => () => {
      storeManager?.releaseSaga(sagaKey, subscriberId);
    },
    [sagaKey, storeManager, subscriberId],
  );

  return { sagaInjected: isInjected };
}
